import request from '@/utils/request'


// 查询后巷屋订单列表
export function listLanewayHouses(query) {
  return request({
    url: '/order/orderLanewayHouses/list',
    method: 'get',
    params: query
  })
}

// 查询后巷屋订单分页
export function pageLanewayHouses(query) {
  return request({
    url: '/order/orderLanewayHouses/page',
    method: 'get',
    params: query
  })
}

// 查询后巷屋订单详细
export function getLanewayHouses(data) {
  return request({
    url: '/order/orderLanewayHouses/detail',
    method: 'get',
    params: data
  })
}

// 新增后巷屋订单
export function addLanewayHouses(data) {
  return request({
    url: '/order/orderLanewayHouses/add',
    method: 'post',
    data: data
  })
}

// 修改后巷屋订单
export function updateLanewayHouses(data) {
  return request({
    url: '/order/orderLanewayHouses/edit',
    method: 'post',
    data: data
  })
}

// 删除后巷屋订单
export function delLanewayHouses(data) {
  return request({
    url: '/order/orderLanewayHouses/delete',
    method: 'post',
    data: data
  })
}
